<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card>
          <div slot="header" class="clearfix">
            <span>用户管理</span>
            <el-button type="primary" style="float: right" size="mini" @click="handleAdd()"
              icon="el-icon-circle-plus-outline">添加用户</el-button>
          </div>
          <div>
            <el-input clearable style="width:30%;float:right" type="text" v-model="search" placeholder="输入电话号检索">
            </el-input>
            <el-table :data="allUserList" style="width: 100%">
              <el-table-column prop="userName" label="用户名称" sortable> </el-table-column>
              <el-table-column prop="phoneNumber" label="电话" sortable> </el-table-column>
              <el-table-column prop="phoneNumber" label="绑定学生">
                <template slot-scope="scope">
                  <span>{{showStudent(scope.row.students)}}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否已经绑定微信">
                <template slot-scope="scope">
                  <span :class="scope.row.isActivated ? 'el-icon-check already' : 'el-icon-close yet'"></span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="right">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="handleDelete(scope.row)" icon="el-icon-circle-close">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card>
          <div slot="header" class="clearfix">
            <span>学生管理</span>
            <el-button type="primary" style="float: right" size="mini" @click="handleAddStudent()"
              icon="el-icon-circle-plus-outline">添加学生</el-button>
          </div>
          <div>
            <el-input clearable style="width:30%;float:right" type="text" v-model="search" placeholder="输入姓名检索">
            </el-input>
            <el-table :data="allStudentList" style="width: 100%">
              <el-table-column prop="studentName" label="名称" sortable> </el-table-column>
              <el-table-column prop="phoneNumber" label="绑定用户"> </el-table-column>
              <el-table-column label="操作" align="right">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="handleDeleteStudent(scope.row)"
                    icon="el-icon-circle-close">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" class="pub_dialog" title="添加用户-录入信息"
      :visible.sync="showAddModel" width="40%">
      <el-form :model="addForm" status-icon :rules="rules" ref="addForm" label-width="100px">
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="addForm.userName" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phoneNumber">
          <el-input v-model="addForm.phoneNumber" prefix-icon="el-icon-phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddModel = false">取消</el-button>
        <el-button type="primary" @click="submit()" :disabled="!canSubmit" icon="el-icon-circle-check">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" class="pub_dialog" title="添加学生-录入信息"
      :visible.sync="showAddStudentModel" width="40%">
      <el-form :model="addStudentForm" status-icon :rules="rules" ref="addStudentForm" label-width="100px">
        <el-form-item label="姓名" prop="studentName">
          <el-input v-model="addStudentForm.studentName" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- <el-form-item label="添加用户" prop="IsAddUser">
          <el-switch v-model="addStudentForm.IsAddUser" active-color="#13ce66">
          </el-switch>
        </el-form-item> -->
        <el-form-item v-if="addStudentForm.IsAddUser" label="用户电话" prop="phoneNumber">
          <el-input v-model="addStudentForm.phoneNumber" prefix-icon="el-icon-phone"></el-input>
        </el-form-item>
        <el-form-item v-else label="选择用户" prop="studentName">
          <el-select v-model="addStudentForm.userId" placeholder="请选择" style="width:100%">
            <el-option v-for="item in allUserList" :key="item.value" :label="item.phoneNumber" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddStudentModel = false">取消</el-button>
        <el-button type="primary" @click="submitStudent()" :disabled="!canSubmitStudent" icon="el-icon-circle-check">提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    createNamespacedHelpers
  } from "vuex"
  const student = createNamespacedHelpers("student")

  export default {
    name: "StudentsManagement",
    components: {},
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("姓名不能为空"))
        }
      }
      var checkPhoneNum = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("电话不能为空"))
        }
      }
      return {
        addForm: {
          userName: "",
          phoneNumber: ""
        },
        addStudentForm: {
          studentName: "",
          IsAddUser: false,
          phoneNumber: "",
          userId: "",
        },
        rules: {
          studentName: [{
            validator: checkName,
            trigger: "blur"
          }],
          phoneNumber: [{
            validator: checkPhoneNum,
            trigger: "blur"
          }],
        },
        showAddModel: false,
        search: '',
        showAddStudentModel: false,
      }
    },
    computed: {
      ...student.mapGetters(["allUser", "allStudents"]),
      allUserList() {
        return this.allUser
      },
      allStudentList() {
        return this.allStudents
      },
      canSubmit() {
        return !!this.addForm.phoneNumber
      },
      canSubmitStudent() {
        return this.addStudentForm.IsAddUser ? this.addStudentForm.studentName && this.addStudentForm.phoneNumber : this
          .addStudentForm.studentName && this.addStudentForm.userId
      },
    },
    watch: {},
    methods: {
      ...student.mapActions(["getAllUser", "addUser", "deleteUser", "getAllStudents", "addStudent", "deleteStudent"]),
      showStudent(student) {
        if (student) {
          let arr = []
          student.forEach(e => {
            arr.push(e.studentName)
          })
          return arr.toString()
        } else {
          return ''
        }
      },
      handleAdd() {
        this.showAddModel = true
        this.$nextTick(() => {
          this.$refs["addForm"].resetFields()
        })
      },
      handleAddStudent() {
        this.showAddStudentModel = true
        this.$nextTick(() => {
          this.$refs["addStudentForm"].resetFields()
        })
      },
      handleDelete(data) {
        this.$confirm(`确认删除${data.userName}? \n 此用户已绑定学生：${this.showStudent(data.students)}`, "删除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true
          })
          .then(() => {
            this.deleteUser(data.id)
              .then(() => {
                this.$message({
                  type: "success",
                  message: "删除成功"
                })
                this.freshData()
              })
              .catch(() => {
                this.$message.error("删除失败")
              })
          })
          .catch(() => {
            this.$message("取消删除")
          })
      },
      handleDeleteStudent(data) {
        this.$confirm(`确认删除${data.studentName}?`, "删除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true
          })
          .then(() => {
            this.deleteStudent(data.id)
              .then(() => {
                this.$message({
                  type: "success",
                  message: "删除成功"
                })
                this.freshData()
              })
              .catch(() => {
                this.$message.error("删除失败")
              })
          })
          .catch(() => {
            this.$message("取消删除")
          })
      },
      submit() {
        this.addUser(this.addForm)
          .then(() => {
            this.$message({
              type: "success",
              message: "添加成功"
            })
            this.freshData()
            this.showAddModel = false
          })
          .catch(() => {
            this.$message.error("添加失败")
            this.showAddModel = false
          })
      },
      submitStudent() {
        this.addStudent(this.addStudentForm)
          .then(() => {
            this.$message({
              type: "success",
              message: "添加成功"
            })
            this.freshData()
            this.showAddStudentModel = false
          })
          .catch(() => {
            this.$message.error("添加失败")
            this.showAddStudentModel = false
          })
      },
      freshData() {
        this.getAllUser()
        this.getAllStudents()
      }
    },
    mounted() {
      this.freshData()
    }
  }
</script>
<style scoped>
  .already {
    color: #67C23A;
  }

  .yet {
    color: #F56C6C;
  }
</style>